<template>
  <navbar>
    <div class="navbar-brand" @click="$router.go(-1)">
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.6666 9.66667V12.3333H5.66663L13 19.6667L11.1066 21.56L0.546631 11L11.1066 0.440002L13 2.33334L5.66663 9.66667H21.6666Z"
          fill="#F2F2F2"
        />
      </svg>
    </div>
    <h6 class="title-navbar">
      Ubah Password
    </h6>
  </navbar>

  <div class="profile__detail-wrapper">
    <form class="needs-validation" @submit.prevent="handleSubmitChangePassword">
      <div class="mb-3">
        <label for="oldPassword" class="form-label">Password Lama</label>
        <input
          type="password"
          class="form-control form__group-profile"
          placeholder="Masukkan Password Lama"
          v-model="oldPassword"
          @keyup="handleKeyupOldPassword"
        />
        <div class="invalid-feedback d-block">
          {{ messageOldPassword }}
        </div>
      </div>
      <div class="mb-3">
        <label for="firstname" class="form-label">Password Baru</label>
        <input
          type="password"
          class="form-control form__group-profile"
          placeholder="Masukkan Password Baru"
          v-model="newPassword"
          @keyup="handleKeyupPassword"
        />
        <div class="invalid-feedback d-block">
          {{ messageNewPassword }}
        </div>
      </div>
      <div class="mb-3">
        <label for="lastname" class="form-label">Konfirmasi Password</label>
        <input
          type="password"
          class="form-control form__group-profile"
          placeholder="Masukkan Konformasi Password"
          :disabled="newPassword === null || newPassword === ''"
          v-model="confirmPassword"
          @keyup="handleKeyupConfirmPassword"
        />
        <div class="invalid-feedback d-block">
          {{ messageConfirmPassword }}
        </div>
      </div>
      <div class="group__donation-wrapper">
        <button type="submit" class="btn btn-warning btn-pay" :disabled="isDisabledBtnSave">
          Simpan Perubahan
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import swal from 'sweetalert2'
import Navbar from '../../components/TransactionComponent/NavbarTransaction.vue'

export default {
  name: 'ChangePassword',
  components: { Navbar },
  data() {
    return {
      accessToken: null,
      oldPassword: null,
      newPassword: null,
      confirmPassword: null,
      messageOldPassword: null,
      messageNewPassword: null,
      messageConfirmPassword: null
    }
  },
  computed: {
    isDisabledBtnSave() {
      if (
        this.oldPassword === null ||
        this.oldPassword === '' ||
        this.newPassword === null ||
        this.newPassword === '' ||
        this.confirmPasword === null ||
        this.confirmPasword === '' ||
        this.confirmPasword === '' ||
        this.messageOldPassword !== null ||
        this.messageNewPassword !== null ||
        this.messageConfirmPassword !== null
      ) {
        return true
      }

      return false
    }
  },
  mounted() {
    this.isAccessToken()
  },
  methods: {
    isAccessToken() {
      if (process.browser) {
        if (localStorage.getItem('access_token')) {
          this.accessToken = localStorage.getItem('access_token')
        }
      }
    },
    handleKeyupOldPassword($event) {
      const form = document.querySelector('.needs-validation')
      if ($event.target.value.length <= 7) {
        this.messageOldPassword = 'Password kurang dari 8 karakter'
        form.classList.add('was-validated')
        $event.target.required = true
      } else {
        this.messageOldPassword = null
        form.classList.remove('was-validated')
        $event.target.required = false
      }
    },
    handleKeyupPassword($event) {
      const form = document.querySelector('.needs-validation')
      if ($event.target.value.length <= 7) {
        this.messageNewPassword = 'Password kurang dari 8 karakter'
        form.classList.add('was-validated')
        $event.target.required = true
      } else {
        this.messageNewPassword = null
        form.classList.remove('was-validated')
        $event.target.required = false
      }
    },
    handleKeyupConfirmPassword($event) {
      const form = document.querySelector('.needs-validation')
      if (this.newPassword !== this.confirmPassword) {
        this.messageConfirmPassword = 'Konfirmasi password salah'
        form.classList.add('was-validated')
        $event.target.required = true
      } else {
        this.messageConfirmPassword = null
        form.classList.remove('was-validated')
        $event.target.required = false
      }
    },
    handleSubmitChangePassword() {
      swal.fire({
        title: '', // add html attribute if you want or remove
        text: 'Mohon Menunggu',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: function() {
          swal.showLoading()
        }
      })
      this.$http
        .post(
          `${process.env.VUE_APP_API_URL}me/password`,
          {
            // eslint-disable-next-line @typescript-eslint/camelcase
            old_password: this.oldPassword,
            password: this.newPassword,
            // eslint-disable-next-line @typescript-eslint/camelcase
            password_confirmation: this.confirmPassword
          },
          {
            headers: {
              Authorization: `Bearer ${this.accessToken}`
            }
          }
        )
        .then(response => {
          swal.close()
          swal.hideLoading()
          if (response.status === 200) {
            swal.fire('Berhasil', 'Profil berhasil diperbaharui', 'success')
          }
        })
        .catch(error => {
          swal.close()
          swal.hideLoading()
          if (error.response.status === 422) {
            swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Password lama tidak sesuai'
            })
          }
        })
    }
  }
}
</script>
